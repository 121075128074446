import React from 'react'
import {graphql} from 'gatsby'
import {Container} from 'theme-ui'
import Layout from '../../@elegantstack/solid-ui-layout/src/Layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import ContentOne from '@solid-ui-blocks/Content/Block01'
import ContentTwo from '@solid-ui-blocks/Content/Block02'
import Footer from '../../blocks/Footer/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import ContentColumn from "../../blocks/Content/Column";

const Legal = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
      <Layout {...props}>
          <Seo title='Aviso Legal'/>
          {/* Modals */}
          <ModalWithTabs content={content['contact']}/>
          {/* Blocks */}
          <Header content={content['header-light']}/>
          <Divider space='6'/>
          <ContentColumn content={content['content-one']}/>
          <Divider space='5'/>
          <Contact content={content['cta']}/>
          <Divider space='5'/>
          <Footer content={content['footer-en']}/>
      </Layout>
  )

}

export const query = graphql`
  query innerpageSiteLegalBlockContentEn {
    allBlockContent(
      filter: { page: { in: ["site/legal-en", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Legal
